import React from "react"
import { PageProps } from "gatsby"
import Section from "../../components/Section"
import Wrapper from "../../components/Wrapper"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import SEO from "../../components/SEO"
export default function Orders(props: PageProps) {
  return (
    <React.Fragment>
      <SEO titleAddition="Use Case - §50a EStG" />
      <Header />
      <div
        className="w-full overflow-x-hidden flex flex-col text-gray-700"
        css={{
          minHeight: "100vh",
        }}
      >
        <Section>
          <Wrapper>
            <div>test</div>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Footer />
          </Wrapper>
        </Section>
      </div>
    </React.Fragment>
  )
}
